import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BlogPost from "../components/blog-post"

const Page = ({ data }) => {
  return (
    <Layout>
      <SEO title="Blog" />
      <section className=" u-pb++">
        <h2>Blog</h2>
        <div className="o-layout">
          {data.allMarkdownRemark.edges.map((edge, i) => (
            <BlogPost
              key={i}
              {...edge.node.frontmatter}
              excerpt={edge.node.excerpt}
            />
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query BlogIndex {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog_posts/" } }
      limit: 20
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          frontmatter {
            created_at: date(formatString: "dddd, Do MMMM, YYYY")
            date_path: date(formatString: "YYYY/MM/DD")
            image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 300) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            image_crop
            image_caption
            image_alt
            updated_at: lastmod
            published
            slug
            title
            tags
          }

          excerpt
        }
      }
    }
    image: file(relativePath: { eq: "me.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default Page
